import httpClient from "./HttpClient";
import ResourceAccessTokenResponse from "../types/ResourceAccessTokenResponse";
import {errorNotification, successNotification} from "./NotificationService";
import AppEntity from "../types/AppEntity";
import RolesService from "./RolesService";
import AppService from "./AppService";
import ReleasesService from "./ReleasesService";

export default function downloadApp(app: AppEntity) {
    const path = RolesService.isAdmin() ? AppService.PATH : ReleasesService.PATH
    httpClient.post<ResourceAccessTokenResponse>(`${process.env.REACT_APP_API_URL}${path}/${app.id}/content/token`, {id: app.id})
        .then(response => {
            window.location.href = `${process.env.REACT_APP_API_URL}${path}/${app.id}/content?token=${response.data.resourceAccessToken}`
            successNotification(`App ${app.name} was successfully downloaded`)
        }).catch((error) => {
        console.log(error)
        errorNotification(`An error occurred while downloading '${app.name}'`)
    });
}
