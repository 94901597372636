import PlatformType from "../types/PlatformType";
import {Observable, Subject, takeUntil} from "rxjs";
import moment from "moment";
import AppEntity from "../types/AppEntity";
import {AxiosError} from "axios";

export function fileToDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}

export function getMediaTypeFromDataUrl(url: string): string | undefined {
    if (!url) return;
    return url.substring(url.indexOf(':') + 1, url.indexOf(';'))
}

export function getBase64FromDataUrl(url: string): string | undefined {
    if (!url) return;
    return url.substr(url.indexOf(',') + 1)
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function base64ToDataUrl(base64: string, mediaType: string) {
    return `data:${mediaType};base64,${base64}`;
}

export function platformTypeToName(platformType: PlatformType) {
    switch (platformType) {
        case PlatformType.ALL:
            return "All platforms"
        case PlatformType.ANDROID:
            return "Android";
        case PlatformType.WINDOWS:
            return "Windows";
        case PlatformType.MACOS:
            return "macOS"
    }
}

export function platformTypeToAcceptedFileTypeExtension(platformType: PlatformType) {
    switch (platformType) {
        case PlatformType.ANDROID:
            return '.apk'
        case PlatformType.WINDOWS:
            return '.exe,.zip'
        case PlatformType.MACOS:
            return '.app,.dmg,.zip'
        default:
            return "*/*"
    }
}

export function temporarySubscription<T>(subject: Subject<T>, unsubscribe: Subject<void>): Observable<T> {
    return subject.asObservable().pipe(takeUntil(unsubscribe))
}

export function formatTime(time: string): string {
    return moment(time).format('YYYY/MM/DD')
}

export function filterStoreMainApp(apps: AppEntity[]): AppEntity | undefined {
    return apps.find(app => app?.androidPackageName === process.env.REACT_APP_APP_REPO_ANDROID_PACKAGE_NAME && app.publishedAt)
}

export function errorMessageOrDefault(error: AxiosError, defaultMessage: string): string {
    return error.response?.data.message ? error.response?.data.message : defaultMessage;
}
