import {TokenState} from "./TokenState";
import {ReplaySubject} from "rxjs";
import httpClient from "./HttpClient";
import {errorNotification} from "./NotificationService";
import Token from "../types/Token";

interface Role {
    id: string
    name: string
}

export default class RolesService {

    static $roles = new ReplaySubject<string[]>(1);

    static loadRoles() {
        httpClient.get<Role[]>('/roles').then((response) => {
            const roles = response.data.map(role => role.name);
            RolesService.$roles.next(roles);
        }).catch(() => {
            errorNotification("An error occurred while loading roles")
        })
    }

    static canAddApplications() {
        const token = TokenState.token();
        if (token) {
            return (TokenState.token() as Token)?.permissions.indexOf("PERM_MANAGE_APPS") > -1;
        }

        return false;
    }

    static isAdmin() {
        const token = TokenState.token();
        if (token) {
            return (TokenState.token() as Token)?.permissions.indexOf("PERM_MANAGE_APPS") > -1;
        }

        return false
    }
}
