import {Modal} from "antd";
import React, {Component} from "react";
import UserForm from "./UserForm";
import User, {UserFormData} from "../../types/User";
import {FormType} from "../../types/FormType";
import UserService from "../../services/UserService";
import {errorNotification, successNotification} from "../../services/NotificationService";
import {AxiosError} from "axios";

interface UserFormModalProps {
    type: FormType
    roles: string[]
    user?: User
    isOpen: boolean
    onClose: () => void
}

export default class UserFormModal extends Component<UserFormModalProps> {

    private userFormRef = React.createRef<UserForm>();

    state = {isLoading: false}

    submit = () => {
        this.userFormRef.current?.formRef.current?.submit()
    }

    handleUserFormData = (userFormData: UserFormData) => {
        this.setState({isLoading: true})
        if (FormType.CREATE === this.props.type) {
            this.createUser(userFormData)
        } else if (FormType.EDIT === this.props.type) {
            this.updateUser(userFormData)
        }

    }

    createUser = (userFormData: UserFormData) => {
        UserService.createUser(userFormData).then(() => {
            UserService.loadUsers()
            successNotification(`User "${userFormData.username}" was successfully created`)
            this.setState({isLoading: false})
            this.props.onClose()
        }).catch((reason: AxiosError) => {
            const errorMessage = this.errorMessageOrDefault(userFormData, reason)
            errorNotification(errorMessage)
            this.setState({isLoading: false})
        })
    }

    errorMessageOrDefault = (userFormData: UserFormData, reason: AxiosError) => {
        return reason.response?.data?.errors?.[0]?.defaultMessage || `User "${userFormData.username}" could not be created`;
    }

    updateUser = (userFormData: UserFormData) => {
        UserService.updateUser(userFormData, this.props.user?.id as string).then(() => {
            UserService.loadUsers()
            successNotification(`User "${userFormData.username}" was successfully updated`)
            this.setState({isLoading: false})
            this.props.onClose()
        }).catch(reason => {
            console.error(reason)
            errorNotification(`User "${userFormData.username}" could not be updated`)
            this.setState({isLoading: false})
        })
    }

    handleClose = () => {
        this.props.onClose();
    }

    render() {
        return <Modal
            title={`${(FormType.CREATE === this.props.type ? "Create" : "Edit")} user`}
            centered
            maskClosable={false}
            visible={this.props.isOpen}
            okText={`${(FormType.CREATE === this.props.type ? "Add" : "Edit")} user`}
            okButtonProps={{onClick: () => this.submit()}}
            onCancel={() => this.handleClose()}
            cancelButtonProps={{hidden: true}}
        >
            {this.props.isOpen &&
            <UserForm
                user={this.props.user}
                roles={this.props.roles}
                onUserFormData={this.handleUserFormData}
                ref={this.userFormRef}/>
            }
        </Modal>
    }
}
