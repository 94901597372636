import React, {Component} from "react";
import {Avatar, Badge, Button, Empty, Tooltip} from "antd";
import {SwapOutlined} from "@ant-design/icons";
import {fileToDataUrl} from "../../services/Helpers";
import "./ApplicationIcon.css"

interface ApplicationIconProps {
    onIconChange: (icon: string) => void
    icon?: string
}

interface ApplicationIconState {
    icon: string;
}

export default class ApplicationIcon extends Component<ApplicationIconProps, ApplicationIconState> {
    private inputRef = React.createRef<HTMLInputElement>();

    state = {
        icon: ''
    }

    handleFileChange = (event: any) => {
        const files = event.target.files;
        if (files && files[0]) {
            const file = files[0];
            fileToDataUrl(file).then(dataUrl => {
                this.setState({icon: dataUrl})
                this.props.onIconChange(dataUrl)
            })
        }

        event.target.value = '';
    }

    render() {
        return <><Badge
            count={<Tooltip title="Change icon"><Button type="primary" shape="circle"
                                                        icon={<SwapOutlined/>}
                                                        className="BtnChangeIcon"
                                                        size="small"
                                                        onClick={() => this.inputRef.current?.click()}/></Tooltip>}>
            <Avatar size={80}
                    src={this.state.icon || this.props.icon || Empty.PRESENTED_IMAGE_SIMPLE}/>
        </Badge>
            <input type="file" ref={this.inputRef} accept="image/png,image/jpeg,image/svg+xml,image/webp"
                   className="display-none" onChange={this.handleFileChange}/>
        </>
    }
}
