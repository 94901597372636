import {ReplaySubject} from "rxjs";
import AppEntity from "../types/AppEntity";
import httpClient from "./HttpClient";
import {errorNotification} from "./NotificationService";

export default class ReleasesService {

    static PATH = '/releases'

    static releases$: ReplaySubject<AppEntity[]> = new ReplaySubject<AppEntity[]>(1);

    static loadReleases() {
        httpClient.get<AppEntity[]>(ReleasesService.PATH).then((response) => {
            ReleasesService.releases$.next(response.data);
        }).catch(() => {
            errorNotification("An error occurred while loading the released apps")
        })
    }

    static release(app: AppEntity) {
        return httpClient.post(ReleasesService.PATH, {id: app.id});
    }
}
