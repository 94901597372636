import React, {Component} from "react";
import {Form, FormInstance, Input, Radio} from "antd";
import {AndroidOutlined, AppleOutlined, WindowsOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import ApplicationUpload from "../application-upload/ApplicationUpload";
import ApplicationIcon from "../application-icon/ApplicationIcon";
import {
    base64ToDataUrl,
    errorMessageOrDefault,
    getBase64FromDataUrl,
    getMediaTypeFromDataUrl
} from "../../services/Helpers";
import AppService from "../../services/AppService";
import {validateName, validateVersion} from "./FormValidators";
import {errorNotification, successNotification} from "../../services/NotificationService";
import PlatformType from "../../types/PlatformType";
import AppEntity from "../../types/AppEntity";
import {FormType} from "../../types/FormType";

const layout = {
    labelCol: {xs: 8, sm: 24, md: 5},
    wrapperCol: {xs: 24, sm: 24, md: 20},
};

interface AddApplicationFormState {
    isLoading: boolean
    platformType: PlatformType
}

interface AddApplicationFormProps {
    onSubmitStart: () => void
    onSubmitEnd: (closeModal?: boolean) => void
    app?: AppEntity
    type: FormType
}

export default class ApplicationForm extends Component<AddApplicationFormProps, AddApplicationFormState> {
    formRef = React.createRef<FormInstance>();
    private applicationFile?: File;

    onFinish = (values: any) => {
        const {name, platform, version, description, icon} = values
        const iconBase64EncodedContent = getBase64FromDataUrl(icon) as string
        const iconMediaType = getMediaTypeFromDataUrl(icon) as string

        const formData = new FormData();
        formData.append('name', name);
        formData.append('platform', platform);
        formData.append('version', version);
        formData.append('description', description);
        formData.append('iconBase64EncodedContent', iconBase64EncodedContent);
        formData.append('iconMediaType', iconMediaType);
        if (this.applicationFile) {
            formData.append('content', this.applicationFile as File)
        }

        this.props.onSubmitStart()
        if (FormType.CREATE === this.props.type) {
            this.createApp(formData);
        } else if (FormType.EDIT === this.props.type) {
            this.updateApp(formData)
        }
    };

    createApp = (formData: FormData) => {
        AppService.createApp(formData).then(() => {
            AppService.loadApps()
            successNotification(`Application ${formData.get('name')}  was successfully uploaded`)
            this.formRef.current?.resetFields();
            this.props.onSubmitEnd()
        }).catch((error) => {
                errorNotification(errorMessageOrDefault(error, `An error occurred while uploading ${formData.get('name')}.`))
                this.props.onSubmitEnd(false)
            }
        )
    }

    updateApp = (formData: FormData) => {
        AppService.updateApp(formData, this.props.app?.id as string).then(() => {
            AppService.loadApps()
            successNotification(`Application ${formData.get('name')}  was successfully updated`)
            this.onReset()
            this.props.onSubmitEnd()
        }).catch((error) => {
                errorNotification(errorMessageOrDefault(error, `An error occurred while updating ${formData.get('name')}.`))
                this.props.onSubmitEnd(false)
            }
        )
    }

    onReset = () => {
        this.formRef.current!.resetFields();
        this.populateFormFields()
    };

    applicationSelectHandler = (applicationFile?: File): void => {
        this.applicationFile = applicationFile;
        this.formRef.current?.setFieldsValue({appFile: applicationFile ? true : undefined})
    }

    onIconChangeHandler = (iconDataUrl: string) => {
        this.formRef.current?.setFieldsValue({icon: iconDataUrl})
    }

    onPlatformTypeChangeHandler = (event: any) => {
        this.setState({platformType: event.target.value})
    }

    populateFormFields = () => {
        const {app} = this.props
        if (app) {
            const {name, version, platform, description, iconBase64EncodedContent, iconMediaType} = app
            const icon = base64ToDataUrl(iconBase64EncodedContent, iconMediaType);
            this.formRef.current?.setFieldsValue({
                name,
                version,
                platform,
                description,
                icon
            });
        }
    }

    componentDidMount() {
        if (this.props.type === FormType.EDIT) {
            this.populateFormFields()
        }
    }

    render() {
        const icon = this.props.type === FormType.EDIT ? base64ToDataUrl(this.props.app?.iconBase64EncodedContent as string, this.props.app?.iconMediaType as string) : undefined;
        return (
            <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                <Form.Item name="name" label="Name"
                           rules={[{required: true, message: 'Name is required'}, validateName]} hasFeedback>
                    <Input maxLength={32} minLength={1}/>
                </Form.Item>

                <Form.Item name="platform" label="Platform"
                           rules={[{required: true, message: 'Please select a platform'}]} hasFeedback>
                    <Radio.Group onChange={this.onPlatformTypeChangeHandler}>
                        <Radio.Button value="android">Android <AndroidOutlined/></Radio.Button>
                        <Radio.Button value="windows">Windows <WindowsOutlined/></Radio.Button>
                        <Radio.Button value="macos">macOS <AppleOutlined/></Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="version" label="Version"
                           rules={[{required: true, message: 'Version is required'}, validateVersion]}
                           hasFeedback>
                    <Input placeholder="1.0.0"/>
                </Form.Item>
                <Form.Item name="description" label="Description"
                           rules={[{required: true, message: 'Description is required'}]} hasFeedback>
                    <TextArea showCount maxLength={500} rows={3}/>
                </Form.Item>
                <Form.Item name="icon" label="App icon" rules={[{required: true, message: 'Icon is required'}]}
                           hasFeedback>
                    <ApplicationIcon
                        icon={icon}
                        onIconChange={this.onIconChangeHandler}/>
                </Form.Item>
                <Form.Item name="appFile" label="App file"
                           rules={[{
                               required: this.props.type === FormType.CREATE,
                               message: 'Application file is required'
                           }]} hasFeedback>
                    <ApplicationUpload onSelect={this.applicationSelectHandler}
                                       platformType={this.state?.platformType || this.props.app?.platform}/>
                </Form.Item>
            </Form>
        );
    }
}
