import {Button, Tag} from 'antd';
import React from "react";
import {CloseOutlined, UploadOutlined} from "@ant-design/icons";
import "./ApplicationUpload.css"
import {TweenOneGroup} from 'rc-tween-one';
import {platformTypeToAcceptedFileTypeExtension} from "../../services/Helpers";
import PlatformType from "../../types/PlatformType";

interface ApplicationUploadPros {
    onSelect: (file?: File) => void
    platformType: PlatformType
}


export default class ApplicationUpload extends React.Component<ApplicationUploadPros> {

    private inputRef = React.createRef<HTMLInputElement>();

    state = {
        applicationFileName: ''
    }

    openSelectFileDialog = (event: any) => {
        this.inputRef.current?.click()
    }

    fileChangeHandler = (event: any) => {
        const files = event.target.files;
        if (files && files[0]) {
            const applicationFile = files[0];
            this.setState({applicationFileName: applicationFile.name})
            this.props.onSelect(applicationFile)
        }
        event.target.value = ''
    }

    removeTag = (event: any) => {
        event.preventDefault();
        this.setState({applicationFileName: ''})
        this.props.onSelect(undefined)
    }

    render() {
        return (
            <div className="ApplicationUpload">
                <Button
                    type="primary"
                    icon={<UploadOutlined/>}
                    onClick={this.openSelectFileDialog}
                >
                    Select application
                </Button>
                <input type={'file'} ref={this.inputRef} className="display-none"
                       accept={platformTypeToAcceptedFileTypeExtension(this.props?.platformType)}
                       onChange={this.fileChangeHandler}/>
                <TweenOneGroup
                    enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        delay: 200,
                        duration: 100,
                        onComplete: (e: any) => {
                            e.target.style = '';
                        },
                    }}
                    leave={{opacity: 0, width: 0, scale: 0, duration: 200}}
                    appear={false}
                >
                    {this.state.applicationFileName &&
                    <span key={this.state.applicationFileName} className="Tag">
                        <Tag
                            closable
                            closeIcon={<CloseOutlined style={{color: "red"}}/>}
                            onClose={this.removeTag}
                        >
                            <div className="ApplicationFileName"><span>{this.state.applicationFileName}</span></div>
                        </Tag>
                      </span>
                    }
                </TweenOneGroup>
            </div>
        );
    }
}
