import {Modal} from "antd";
import React, {Component} from "react";
import AppEntity from "../../types/AppEntity";
import LoginForm from "./LoginForm";

interface LoginFormModalProps {
    app?: AppEntity
    isOpen: boolean
    onClose: () => void
}

export default class LoginFormModal extends Component<LoginFormModalProps> {

    private loginFormRef = React.createRef<LoginForm>();

    state = {isLoading: false}

    submit = () => {
        this.loginFormRef.current?.formRef.current?.submit()
    }

    handleSubmitStart = () => {
        this.setState({isLoading: true})
    }

    handleClose = () => {
        this.props.onClose();
    }

    render() {
        return <Modal
            title="Login"
            centered
            maskClosable={false}
            closable={false}
            visible={this.props.isOpen}
            okText="Login"
            okButtonProps={{onClick: () => this.submit()}}
            cancelButtonProps={{hidden: true}}
            onCancel={() => this.handleClose()}
        >
            {this.props.isOpen &&
            <LoginForm onSubmitStart={this.handleSubmitStart}
                       ref={this.loginFormRef}/>
            }
        </Modal>
    }
}
