import React, {Component} from "react";
import {Form, FormInstance, Input} from "antd";
import PlatformType from "../../types/PlatformType";
import SessionService from "../../services/SessionService";

const layout = {
    labelCol: {xs: 8, sm: 24, md: 5},
    wrapperCol: {xs: 24, sm: 24, md: 20},
};

interface LoginFormFormState {
    isLoading: boolean
    platformType: PlatformType
}

interface LoginFormProps {
    onSubmitStart: () => void
}

export default class LoginForm extends Component<LoginFormProps, LoginFormFormState> {
    formRef = React.createRef<FormInstance>();

    onFinish = (values: any) => {
        const {username, password} = values
        this.loginUser({username, password})
        this.props.onSubmitStart()
    };


    loginUser = (credentials: any) => {
        SessionService.create(credentials)
    }

    render() {
        return (
            <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                <Form.Item name="username" label="Username"
                           rules={[{required: true, message: 'Username is required'}]} hasFeedback>
                    <Input/>
                </Form.Item>
                <Form.Item name="password" label="Password"
                           rules={[{required: true, message: 'Password is required'}]}
                           hasFeedback>
                    <Input type="password"/>
                </Form.Item>
            </Form>
        );
    }
}
