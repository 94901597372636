import {ReplaySubject} from "rxjs";
import AppEntity from "../types/AppEntity";
import httpClient from "./HttpClient";
import {errorNotification} from "./NotificationService";

export default class AppService {
    static PATH = "/apps"

    static apps$: ReplaySubject<AppEntity[]> = new ReplaySubject<AppEntity[]>(1);

    static loadApps() {
        httpClient.get<AppEntity[]>(AppService.PATH).then((response) => {
            AppService.apps$.next(response.data);
        }).catch(() => {
            errorNotification("An error occurred while loading the apps")
        })
    }

    static createApp(formData: FormData) {
        return httpClient.post(AppService.PATH, formData);
    }

    static updateApp(formData: FormData, id: string) {
        return httpClient.put(`${AppService.PATH}/${id}`, formData);
    }

    static deleteApp(id: string) {
        return httpClient.delete(`${AppService.PATH}/${id}`);
    }
}
