import {FormInstance, RuleObject} from "rc-field-form/lib/interface";

const semanticVersionRegex = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

const validateName = (form: FormInstance) => ({
    validator(rule: RuleObject, value: string) {
        if (!value) {
            return Promise.resolve();
        }
        if (!/^[A-Za-z ]{1,32}$/.test(value))
            return Promise.reject('Name should contain only letters and spaces')
        return Promise.resolve()
    }
})

const validateVersion = (form: FormInstance) => ({
    validator(rule: RuleObject, value: string) {
        if (!value) {
            return Promise.resolve();
        }
        if (!semanticVersionRegex.test(value))
            return Promise.reject('Only semantic version is allowed')
        return Promise.resolve()
    }
})

export {validateName, validateVersion};
