import React from "react";
import styles from "./AppAdvertising.module.css"
import logo from "../../logo.png"
import {Button} from "antd";
import {AndroidOutlined, CaretDownOutlined, DownloadOutlined} from "@ant-design/icons";
import AppEntity from "../../types/AppEntity";
import downloadApp from "../../services/DownloadApp";

interface AppAdvertisingProps {
    app?: AppEntity
    onClose: () => void
}

export function AppAdvertising(props: AppAdvertisingProps) {
    if (!props.app) {
        return <></>
    }
    return <div className={styles.container}>

        <div className={styles.close}>
            <Button icon={<CaretDownOutlined/>} onClick={props.onClose}/>
        </div>
        <img src={logo} className={styles.logo} alt="logo"/>
        <div className={styles.actionContainer}>
            <span className={styles.text}>Is available for your <AndroidOutlined /> device</span>
            <div className={styles.download}>
                <Button icon={<DownloadOutlined/>} onClick={() => downloadApp(props.app as AppEntity)}>
                    Download
                </Button></div>
        </div>
    </div>
}
