import {Button, List, Popconfirm} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import UserRole from "./UserRole";
import React, {useState} from "react";
import User from "../../types/User";

interface UserContainerProps {
    user: User
    onEdit: (user: User) => void
    onDelete: (user: User) => void
}

export default function UserContainer(props: UserContainerProps) {
    const {user} = props;
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    return <List.Item
      actions={[<Button size="small" type="primary" ghost
                        onClick={() => props.onEdit(user)}>Edit</Button>,
          <Popconfirm
            title={`Are you sure you want to delete ${user.username}?`}
            visible={isConfirmationVisible}
            icon={<DeleteOutlined/>}
            onConfirm={() => {
                props.onDelete(user)
                setIsConfirmationVisible(false)
            }}
            onCancel={() => setIsConfirmationVisible(false)}
          >
              <Button size="small" danger onClick={() => setIsConfirmationVisible(true)}>Delete</Button>
          </Popconfirm>

      ]}
    >
        <List.Item.Meta
          title={<span>{user.username}</span>}
        />
        <div style={{display: 'flex'}}>{user.roles.map(role => <UserRole key={role} role={role}/>)}</div>
    </List.Item>
}
