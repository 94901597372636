import {notification} from "antd";

const successNotification = (message: string) => {
    notification.success({message})
}

const errorNotification = (message: string) => {
    notification.error({message})
}


export {successNotification, errorNotification}
