import {ReplaySubject} from "rxjs";
import User from "../types/User";
import SessionService from "./SessionService";
import jwtDecode from "jwt-decode";
import moment from "moment";
import Token from "../types/Token";
import Session from "../types/Session";

export class TokenState {
    static ACCESS_TOKEN = "ACCESS_TOKEN";
    static REFRESH_TOKEN = "REFRESH_TOKEN";
    static storage = localStorage;
    static user$ = new ReplaySubject<User | undefined>();

    static TOKEN_REFRESH_TIMEOUT = 840_000;
    static TOKEN: Token | null = null;

    static init() {
        setTimeout(() => {
            TokenState.hydrateSessionOrLogoutUser()
            TokenState.watchForTokenExpiration()
        })
    }

    static isLogged(): boolean {
        return !!TokenState.storage.getItem(TokenState.ACCESS_TOKEN)
    }

    static bearerToken() {
        const accessToken = this.accessToken();
        if (!accessToken) {
            return '';
        }
        return `Bearer ${accessToken}`;
    }

    static permissions() {
        TokenState.accessToken()
    }

    static emmit() {
        if (TokenState.accessToken()) {
            const token = TokenState.decodeAccessToken(TokenState.accessToken() as string);
            const user = TokenState.tokenToUser(token)
            TokenState.user$.next(user)
        } else {
            TokenState.user$.next(undefined)
        }
    }

    update(session: Session) {
        TokenState.updateAccessToken(session.accessToken);
        TokenState.updateRefreshToken(session.refreshToken);
    }

    static logout() {
        TokenState.storage.removeItem(TokenState.ACCESS_TOKEN);
        TokenState.storage.removeItem(TokenState.REFRESH_TOKEN);
        TokenState.user$.next(undefined);
        TokenState.TOKEN = null
    }

    static accessToken(): string | null {
        return TokenState.storage.getItem(TokenState.ACCESS_TOKEN);
    }

    static refreshToken(): string | null {
        return TokenState.storage.getItem(TokenState.REFRESH_TOKEN)
    }

    static updateAccessToken(accessToken: string) {
        TokenState.storage.setItem(TokenState.ACCESS_TOKEN, accessToken)
        TokenState.TOKEN = TokenState.decodeAccessToken(accessToken)
    }

    static updateRefreshToken(refreshToken: string) {
        TokenState.storage.setItem(TokenState.REFRESH_TOKEN, refreshToken)
    }

    static tokenToUser(token: Token): User {
        // TODO Permissions are not roles
        return {
            username: token.sub,
            roles: token.permissions,
            isActive: true
        } as User;
    }

    static decodeAccessToken(token: string): Token {
        return jwtDecode(token) as Token
    }

    static token(): Token | null {
        if (TokenState.TOKEN) {
            return TokenState.TOKEN
        }
        if (TokenState.accessToken()) {
            return TokenState.decodeAccessToken(TokenState.accessToken() as string)
        }
        return null
    }

    static watchForTokenExpiration() {
        setInterval(() => {
            TokenState.hydrateSessionOrLogoutUser()
        }, TokenState.TOKEN_REFRESH_TIMEOUT)
    }

    static hydrateSessionOrLogoutUser() {
        const refreshToken = TokenState.refreshToken();
        if (refreshToken && !this.isTokenExpired(TokenState.decodeAccessToken(refreshToken).exp)) {
            TokenState.hydrateSession(refreshToken);
        } else {
            this.logout()
        }
    }

    static isTokenExpired(expirationDate: number) {
        return moment(expirationDate).isBefore(moment().unix())
    }

    static hydrateSession(refreshToken: string) {
        SessionService.hydrate(refreshToken, (response) => {
            TokenState.updateAccessToken(response.accessToken)
            TokenState.updateRefreshToken(response.refreshToken)
            TokenState.emmit()
        })
    }
}

TokenState.init()
