import {ReplaySubject} from "rxjs";
import httpClient from "./HttpClient";
import {errorNotification} from "./NotificationService";
import User, {UserFormData} from "../types/User";

export default class UserService {
    static users$: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

    static loadUsers() {
        httpClient.get<User[]>('/users').then((response) => {
            // TODO To be reviewed if is needed to display inactive users
            UserService.users$.next(response.data.filter(user => user.isActive));
        }).catch(() => {
            errorNotification("An error occurred while loading the users")
        })
    }

    static createUser(userFormData: UserFormData) {
        return httpClient.post('/users', userFormData);
    }

    static updateUser(userFormData: UserFormData, id: string) {
        return httpClient.put(`/users/${id}`, userFormData);
    }

    static deleteUser(id: string) {
        return httpClient.delete(`/users/${id}`);
    }
}
