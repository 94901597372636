import React from "react"
import {List} from "antd";
import User from "../../types/User";
import UserContainer from "./UserContainer";

interface UserListProps {
    users: User[]
    onEdit: (user: User) => void
    onDelete: (user: User) => void
}

export default function UserList(props: UserListProps) {
    return (<List
      itemLayout="horizontal"
      dataSource={props.users}
      renderItem={user => <UserContainer user={user} onEdit={props.onEdit} onDelete={props.onDelete}/>}
    />)
}
