import React, {Component} from 'react';
import './App.css';
import {Button, Empty, Layout, Menu} from "antd";
import {
    AndroidOutlined,
    AppleOutlined,
    AppstoreAddOutlined,
    AppstoreOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UsergroupAddOutlined,
    WindowsOutlined,
} from '@ant-design/icons';
import AppCard from "./components/app-card/AppCard";
import AppService from "./services/AppService";
import AppEntity, {AppEntityGroup} from "./types/AppEntity";
import User from "./types/User"
import PlatformType from "./types/PlatformType";
import ApplicationFormModal from "./components/application-form-modal/ApplicationFormModal";
import LoginFormModal from "./components/login-form/LoginFormModal";
import {TokenState} from "./services/TokenState";
import RolesService from "./services/RolesService";
import ReleasesService from "./services/ReleasesService";
import UserListModal from "./components/users/UserListModal";
import {FormType} from "./types/FormType";
import {errorNotification, successNotification} from "./services/NotificationService";
import {Subject} from "rxjs";
import {filterStoreMainApp, temporarySubscription} from "./services/Helpers";
import logo from "./logo.png"
import {AppAdvertising} from "./components/mobile-advertising/AppAdvertising";
import {isMobile} from "react-device-detect"
import {motion} from 'framer-motion';

const {Header, Sider, Content} = Layout;

interface AppState {
    collapsed: boolean,
    apps: AppEntity[],
    loading: boolean,
    user?: User,
    isInitialUserLoaded: boolean
    groupedApps?: AppEntityGroup,
    selectedPlatform: PlatformType
    isModalOpen: boolean
    isUserModalOpen: boolean
    isAppAdvertisingShown: boolean
    appToEdit?: AppEntity
}

interface AppProps {

}

export default class App extends Component<AppProps, AppState> {
    unsubscribe$ = new Subject<void>();

    state = {
        collapsed: isMobile,
        apps: [],
        groupedApps: undefined,
        loading: true,
        isInitialUserLoaded: false,
        user: undefined,
        isModalOpen: false,
        isUserModalOpen: false,
        selectedPlatform: PlatformType.ALL,
        isAppAdvertisingShown: isMobile,
        appToEdit: undefined
    };

    componentDidMount() {
        temporarySubscription(TokenState.user$, this.unsubscribe$).subscribe(user => {
            this.setState({user, isInitialUserLoaded: true})
            this.loadAppsByRole(user)
        })
    }

    componentWillUnmount() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    loadAppsByRole = (user: User | undefined) => {
        if (user) {
            if (RolesService.isAdmin()) {
                temporarySubscription(AppService.apps$, this.unsubscribe$).subscribe((apps: AppEntity[]) => this.groupApps(apps));
                AppService.loadApps();
            } else {
                temporarySubscription(ReleasesService.releases$, this.unsubscribe$).subscribe((apps: AppEntity[]) => this.groupApps(apps));
                ReleasesService.loadReleases()
            }
        }
    }

    groupApps = (apps: AppEntity[]) => {
        const groupedApps = {
            [PlatformType.ALL]: apps,
            [PlatformType.ANDROID]: new Array<AppEntity>(),
            [PlatformType.WINDOWS]: new Array<AppEntity>(),
            [PlatformType.MACOS]: new Array<AppEntity>(),
        };

        apps.forEach((app: AppEntity) => {
            groupedApps[app.platform].push(app);
        })
        this.setState({loading: false, apps, groupedApps})
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    logout = () => {
        this.setState({apps: [], groupedApps: undefined})
        TokenState.logout()
    }

    updateSelectedPlatform(platform: PlatformType) {
        this.setState({selectedPlatform: platform});
    }

    onAppEditHandler = (app: AppEntity) => {
        this.setState({appToEdit: app, isModalOpen: true})
    }

    onAppDeleteHandler = (app: AppEntity) => {
        AppService.deleteApp(app.id as string)
            .then(() => {
                AppService.loadApps()
                successNotification(`App '${app.name}' was successfully deleted`)
            })
            .catch(error => {
                console.log(error)
                errorNotification(`An error occurred while deleting '${app.name}'`)
            })
    }

    onAppReleaseHandler = (app: AppEntity) => {
        if (app.publishedAt) return
        ReleasesService.release(app).then(() => {
            AppService.loadApps()
            successNotification(`Application '${app.name}' was successfully released`)
        }).catch(error => {
            console.error(error)
            errorNotification(`An error occurred while releasing '${app.name}'`)
        })
    }

    onModalCloseHandler = (closeModal: boolean = true) => {
        if (closeModal) {
            this.setState({isModalOpen: false, appToEdit: undefined})
        }
    }

    onDisplayUsersModalHandler = () => {
        this.setState({isUserModalOpen: true})
    }

    onCloseUsersModalHandler = () => {
        this.setState({isUserModalOpen: false})
    }

    onAppAdvertisingClose = () => {
        this.setState({isAppAdvertisingShown: false})
    }

    render() {
        let appsToDisplay: AppEntity[] = []
        if (this.state.groupedApps) {
            appsToDisplay = this.state.groupedApps[this.state.selectedPlatform] as AppEntity[]
        }

        return (<>
                {this.state.isModalOpen && <ApplicationFormModal
                    type={this.state?.appToEdit ? FormType.EDIT : FormType.CREATE}
                    isOpen={this.state.isModalOpen}
                    app={this.state?.appToEdit}
                    onClose={this.onModalCloseHandler}
                />}
                {
                    !TokenState.isLogged() && this.state.isInitialUserLoaded &&
                    <LoginFormModal isOpen={!this.state.user} onClose={() => {
                    }}/>
                }
                {TokenState.isLogged() && RolesService.isAdmin() && this.state.isUserModalOpen &&
                    <UserListModal isOpen={this.state.isUserModalOpen} onClose={this.onCloseUsersModalHandler}/>}
                <Layout className="App">
                    <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'MenuToggle',
                            onClick: this.toggle,
                        })}
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']}>
                            <Menu.Item key="0" icon={<AppstoreOutlined/>}
                                       onClick={() => this.updateSelectedPlatform(PlatformType.ALL)}>
                                All platforms
                            </Menu.Item>
                            <Menu.Item key="1" icon={<AndroidOutlined/>}
                                       onClick={() => this.updateSelectedPlatform(PlatformType.ANDROID)}> Android </Menu.Item>
                            <Menu.Item key="2" icon={<WindowsOutlined/>}
                                       onClick={() => this.updateSelectedPlatform(PlatformType.WINDOWS)}> Windows </Menu.Item>
                            <Menu.Item key="3" icon={<AppleOutlined/>}
                                       onClick={() => this.updateSelectedPlatform(PlatformType.MACOS)}> macOS </Menu.Item>
                        </Menu>
                        {TokenState.isLogged() && RolesService.isAdmin() &&
                            <div className={`MenuItemLikeButton ${this.state.collapsed ? 'collapsed' : ''}`}
                                 onClick={this.onDisplayUsersModalHandler}>
                                <UsergroupAddOutlined/> <span> Users </span></div>}
                        {TokenState.isLogged() &&
                            <div className={`MenuItemLikeButton ${this.state.collapsed ? 'collapsed' : ''}`}
                                 onClick={() => this.logout()}><LogoutOutlined/>
                                <span> Logout </span></div>}
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="Header">
                            <img src={logo} className="Logo" alt="logo"/>
                            {RolesService.canAddApplications() &&
                                <Button type="dashed" icon={<AppstoreAddOutlined/>} size="large"
                                        onClick={() => this.setState({isModalOpen: true})}>
                                    Add new application
                                </Button>}
                        </Header>
                        <Content
                            className="Content"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                            }}>
                            {TokenState.isLogged() && !this.state.loading && appsToDisplay.length === 0 &&
                                <Empty description={"No application found"}/>}
                            {appsToDisplay.length !== 0 && <motion.div layout className="AppsWrapper">
                                {appsToDisplay.map((app: AppEntity) =>
                                    <AppCard key={app.id} app={app}
                                             onEditHandler={this.onAppEditHandler}
                                             onReleaseHandler={this.onAppReleaseHandler}
                                             onDeleteHandler={this.onAppDeleteHandler}
                                    />)}
                            </motion.div>}
                        </Content>
                    </Layout>
                </Layout>
                {this.state.isAppAdvertisingShown && <AppAdvertising
                    app={filterStoreMainApp(appsToDisplay)}
                    onClose={this.onAppAdvertisingClose}
                />
                }
            </>
        );
    }
}
