import React from "react"
import {Tag} from "antd";

interface UserRoleProps {
    role: string
}

export default function UserRole(props: UserRoleProps) {
    return <Tag color="green">{props.role}</Tag>
}
