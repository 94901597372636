import axios from "axios";
import {TokenState} from "./TokenState";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 300_000,
    headers: {
        'Accept': 'application/json',
        'Consumes': 'application/json'
    }
});

httpClient.interceptors.request.use(function (config) {
    if (TokenState.TOKEN && !TokenState.isTokenExpired(TokenState.TOKEN.exp)) {
        config.headers = {...config.headers, 'Authorization': TokenState.bearerToken()}
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default httpClient
