import httpClient from "./HttpClient";
import {errorNotification} from "./NotificationService";
import {TokenState} from "./TokenState";
import {AxiosError} from "axios";
import Credentials from "../types/Credentials";
import Session from "../types/Session";

export default class SessionService {
    static PATH = '/sessions'

    static create(credentials: Credentials) {
        httpClient.post<Session>(SessionService.PATH, credentials).then((response) => {
            TokenState.updateAccessToken(response.data.accessToken);
            TokenState.updateRefreshToken(response.data.refreshToken);
            TokenState.emmit()
        }).catch((error: AxiosError) => {
            errorNotification(SessionService.errorFromResponseOrDefault(error, "An error occurred while logging in."))
        })
    }

    static delete(refreshToken: string) {
        TokenState.logout()
        return httpClient.post(SessionService.PATH, {refreshToken});
    }

    static hydrate(refreshToken: string, onResponse: (response: Session) => void) {
        return httpClient.put<Session>(`${SessionService.PATH}`, {refreshToken}).then((response) => {
            onResponse(response.data);
        }).catch((e) => {
            errorNotification("Could not update your authorization token")
            TokenState.logout()
        });
    }

    static errorFromResponseOrDefault = (error: AxiosError, defaultMessage: string) => {
        if (error.response?.status === 403) {
            return "The username or password is incorrect. Try again."
        }

        return defaultMessage;
    }
}
