import {Button, Modal} from "antd";
import React, {Component} from "react";
import AppEntity from "../../types/AppEntity";
import ApplicationForm from "../application-form/ApplicationForm";
import {FormType} from "../../types/FormType";

interface ApplicationFormModalProps {
    type: FormType
    app?: AppEntity
    isOpen: boolean
    onClose: (closeModal?: boolean) => void
}

export default class ApplicationFormModal extends Component<ApplicationFormModalProps> {

    private applicationFormRef = React.createRef<ApplicationForm>();

    state = {isLoading: false}

    submit = () => {
        this.applicationFormRef.current?.formRef.current?.submit()
    }

    handleSubmitStart = () => {
        this.setState({isLoading: true})
    }

    handleSubmitEnd = (closeModal?: boolean) => {
        this.setState({isLoading: false})
        if (this.props.type === FormType.EDIT) {
            this.props.onClose(closeModal);
        }
    }

    handleResetModal = () => {
        this.applicationFormRef.current?.onReset()
    }

    handleClose = () => {
        this.handleResetModal();
        this.props.onClose();
    }

    render() {
        return <Modal
            title={this.props.type === FormType.CREATE ? "Add new application" : `Edit ${this.props.app?.name}`}
            centered
            maskClosable={false}
            visible={this.props.isOpen}
            onCancel={() => this.handleClose()}
            footer={[
                <Button key="reset" type="dashed" disabled={this.state.isLoading}
                        onClick={() => this.handleResetModal()}>
                    Reset
                </Button>,
                <Button key="submit" type="primary" loading={this.state.isLoading}
                        onClick={() => this.submit()}>
                    {this.props.type === FormType.CREATE ? "Submit" : "Update"}
                </Button>,
            ]}
        >
            {this.props.isOpen &&
                <ApplicationForm onSubmitStart={this.handleSubmitStart}
                                 onSubmitEnd={this.handleSubmitEnd}
                                 app={this.props.app}
                                 type={this.props.type}
                                 ref={this.applicationFormRef}/>
            }
        </Modal>
    }
}
