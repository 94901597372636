import React, {Component} from "react";
import {Checkbox, Form, FormInstance, Input} from "antd";
import User, {UserFormData} from "../../types/User";

const layout = {
    labelCol: {xs: 8, sm: 24, md: 5},
    wrapperCol: {xs: 24, sm: 24, md: 20},
};

interface UserFormFormState {
    isLoading: boolean
}

interface UserFormProps {
    onUserFormData: (formData: UserFormData) => void
    roles: string[]
    user?: User
}

export default class UserForm extends Component<UserFormProps, UserFormFormState> {
    formRef = React.createRef<FormInstance>();

    defaultRole = "ROLE_USER"

    onFinish = (values: any) => {
        const {username, password, roles} = values
        this.props.onUserFormData({username, password, roles})
    };

    componentDidMount() {
        if (this.props.user) {
            const {username, roles} = this.props.user;
            this.formRef.current?.setFieldsValue({username, roles})
        } else {
            this.formRef.current?.setFieldsValue({roles: [this.defaultRole]})
        }
    }

    render() {
        return (
            <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                <Form.Item name="username" label="Username"
                           rules={[{required: true, message: 'Username is required'}, {
                               min: 5,
                               message: "Username must be at least 5 characters"
                           }, {max: 25, message: "Username cannot be longer than 25 characters"}]} hasFeedback>
                    <Input/>
                </Form.Item>
                <Form.Item name="password" label="Password"
                           rules={[{required: !this.props.user, message: 'Password is required'}, {
                               min: 8,
                               message: "Password must be at least 8 characters"
                           }, {max: 128, message: "Password cannot be longer than 128 characters"}]}
                           hasFeedback>
                    <Input type="password"/>
                </Form.Item>
                <Form.Item name="roles" label="Role">
                    <Checkbox.Group>
                        {this.props.roles.map(role => <Checkbox key={role} value={role}
                                                                disabled={role === this.defaultRole}>{role}</Checkbox>
                        )}
                    </Checkbox.Group>
                </Form.Item>

            </Form>
        );
    }
}
