import {Button, Modal} from "antd";
import React, {Component} from "react";
import UserFormModal from "./UserFormModal";
import User from "../../types/User";
import UserService from "../../services/UserService";
import UserList from "./UserList";
import {FormType} from "../../types/FormType";
import RolesService from "../../services/RolesService";
import {errorNotification, successNotification} from "../../services/NotificationService";
import {Subject} from "rxjs";
import {temporarySubscription} from "../../services/Helpers";
import styles from "./UserListModal.module.css"

interface UserListModalProps {
    isOpen: boolean
    onClose: () => void
}

interface UserListModalState {
    isLoading: boolean
    isUserFormModalOpen: boolean
    user?: User
    users: User[]
    roles: string[]
}

export default class UserListModal extends Component<UserListModalProps, UserListModalState> {

    unsubscribe$ = new Subject<void>();

    state = {
        isLoading: false,
        isUserFormModalOpen: false,
        user: undefined,
        users: [],
        roles: []
    }

    componentDidMount() {
        temporarySubscription(UserService.users$, this.unsubscribe$).subscribe((users) => {
            this.setState({users})
        })
        temporarySubscription(RolesService.$roles, this.unsubscribe$).subscribe(roles => {
            this.setState({roles})
        })
        UserService.loadUsers()
        RolesService.loadRoles()
    }

    componentWillUnmount() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    handleSubmitStart = () => {
        this.setState({isLoading: true})
    }

    handleSubmitEnd = () => {
        this.setState({isLoading: false})
    }

    handleEditUserModalClose = () => {
        this.setState({isUserFormModalOpen: false})
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleNewUserModalOpen = () => {
        this.setState({isUserFormModalOpen: true, user: undefined})
    }

    onUserDeleteHandler = (user: User) => {
        UserService.deleteUser(user.id as string).then(() => {
            UserService.loadUsers()
            successNotification(`User "${user.username}" was successfully deleted`)
            this.setState({isLoading: false})
        }).catch(reason => {
            console.error(reason)
            errorNotification(`User "${user.username}" could not be deleted`)
            this.setState({isLoading: false})
        })
    }

    onUserEditHandler = (user: User) => {
        this.setState({isUserFormModalOpen: true, user})
    }

    render() {
        return <Modal
          title="User list"
          centered
          maskClosable={false}
          visible={this.props.isOpen}
          onCancel={() => this.handleClose()}
          footer={[
              <Button key="new-user" type="primary" onClick={() => this.handleNewUserModalOpen()}
                      disabled={this.state.isLoading}>
                  Add new user
              </Button>,
          ]}
        >
            <div className={styles.UserList}>
                <UserList users={this.state.users} onDelete={this.onUserDeleteHandler}
                          onEdit={this.onUserEditHandler}/>
            </div>
            {this.state.isUserFormModalOpen &&
            <UserFormModal roles={this.state.roles} type={this.state.user ? FormType.EDIT : FormType.CREATE}
                           user={this.state.user}
                           isOpen={this.state.isUserFormModalOpen}
                           onClose={() => this.handleEditUserModalClose()}/>
            }
        </Modal>
    }
}
