import React, {Component} from "react";
import {Avatar, Badge, Button, Card, Popconfirm, Tooltip} from "antd";
import {
    AndroidOutlined,
    AppleOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    SwapOutlined,
    WindowsOutlined
} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import AppEntity from "../../types/AppEntity";
import {base64ToDataUrl, formatBytes, formatTime, platformTypeToName} from "../../services/Helpers";
import "./AppCard.css"
import PlatformType from "../../types/PlatformType";
import {TweenOneGroup} from "rc-tween-one";
import RolesService from "../../services/RolesService";
import downloadApp from "../../services/DownloadApp";
import {motion} from "framer-motion";

interface AppCardProps {
    app: AppEntity
    onEditHandler: (appEntity: AppEntity) => void
    onReleaseHandler: (appEntity: AppEntity) => void
    onDeleteHandler: (appEntity: AppEntity) => void
}

interface AppCardState {
    isHover: boolean
    isConfirmationVisible: boolean
}

export default class AppCard extends Component<AppCardProps, AppCardState> {

    state = {isHover: false, isConfirmationVisible: false}

    platformToIcon = () => {
        switch (this.props.app.platform) {
            case PlatformType.ANDROID:
                return <AndroidOutlined/>
            case PlatformType.WINDOWS:
                return <WindowsOutlined/>
            case PlatformType.MACOS:
                return <AppleOutlined/>
        }
    }

    updateHoverState = (isHover: boolean) => {
        if (!RolesService.isAdmin()) {
            return
        }

        if (!this.state.isHover === isHover) {
            this.setState({isHover})
        }
    }

    render() {
        const {app} = this.props;
        const appStatusClasses = `AppStatus ${app.publishedAt ? "AppStatusReleased" : "AppStatusUnreleased"}`
        return <motion.div layout className="Wrapper" onMouseOver={() => this.updateHoverState(true)}
                    onMouseLeave={() => this.updateHoverState(false)}>
            {RolesService.isAdmin() &&
            <div className={appStatusClasses}
                 onClick={() => this.props.onReleaseHandler(app)}>{app.publishedAt ? "Published " : "Unpublished "}
                <SwapOutlined/></div>}
            <div>
                <TweenOneGroup
                  enter={{
                      scale: 0.8,
                      opacity: 0,
                      duration: 500,
                      onComplete: (e: any) => {
                          e.target.style = '';
                      },
                  }}
                  leave={{opacity: 0, width: 0, scale: 0, duration: 200}}
                  appear={false}
                >
                    {(this.state.isHover || this.state.isConfirmationVisible)&&
                    <div className="ActionButtonsWrapper">
                        <Button
                            type="primary"
                            icon={<EditOutlined/>}
                            className="ActionButton"
                            onClick={() => this.props.onEditHandler(app)}
                        >
                            Edit
                        </Button>
                        <Popconfirm
                            title={`Are you sure you want to delete ${app.name}?`}
                            visible={this.state.isConfirmationVisible}
                            icon={<DeleteOutlined/>}
                            onConfirm={() => this.props.onDeleteHandler(app)}
                            onCancel={() => this.setState({isConfirmationVisible: false})}
                        >
                            <Button
                                icon={<DeleteOutlined/>}
                                className="ActionButton"
                                type="primary"
                                onClick={() => this.setState({isConfirmationVisible: true})}
                                danger
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </div>
                    }
                </TweenOneGroup>
                <Badge.Ribbon text={app.version} color="green" style={{zIndex: 10}}>
                    <Card
                      className="AppPresentationCard"
                      cover={
                          <img
                            alt="Application icon"
                            src={base64ToDataUrl(app.iconBase64EncodedContent, app.iconMediaType)}
                          />
                      }
                      actions={[
                          <Tooltip title="Created at">
                              <span>{formatTime(app.createdAt)}</span>
                          </Tooltip>,
                          <Tooltip title="Size">
                              <span>{formatBytes(app.fileSize)}</span>
                          </Tooltip>,
                          <Tooltip title="Download">
                                <span onClick={() => downloadApp(app)}>
                                    <DownloadOutlined key="download"/></span>
                          </Tooltip>
                      ]}
                    >
                        <Meta
                          avatar={<Tooltip title={platformTypeToName(this.props.app.platform)}>
                              <Avatar
                                size={{xs: 24, sm: 32, md: 40, lg: 40, xl: 40, xxl: 40}}
                                style={{backgroundColor: `var(--${app.platform}-color)`}}
                                icon={this.platformToIcon()}/>
                          </Tooltip>
                          }
                          title={app.name}
                          description={app.description}
                        />
                    </Card>
                </Badge.Ribbon>
            </div>
        </motion.div>
    }
}
